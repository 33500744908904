import { defineStore } from "pinia";
import { CategoryViewModel } from '@/types/CategoryViewModel';
import { ArticleViewModel } from "@/types/ArticleViewModel";

export type RootState = {
    isLoading: boolean;
    hasLoadedArticles: boolean;
    categories: CategoryViewModel[];
    articles: ArticleViewModel[];
};

export const useRootStore = defineStore({
    id: 'appStore',
    state: (): RootState => {
        return {
            isLoading: true,
            categories: [] as CategoryViewModel[],
            articles: [] as ArticleViewModel[],
            hasLoadedArticles: false
        }
    },
    actions: {
        setCategories(categories: CategoryViewModel[]) {
            this.$state.categories = categories;
            this.$state.isLoading = false;
        },
        setArticles(articles: ArticleViewModel[]) {
            this.$state.articles = articles;
            this.$state.hasLoadedArticles = true;
        }
    }
});