import { AxiosRequestConfig } from 'axios';
import * as qs from 'qs';

export const API_BASE = process.env.NODE_ENV === 'production' ? '/api' : 'https://localhost:8787/api/';
export const apiConfig: AxiosRequestConfig = {
    timeout: 15000,
    baseURL: API_BASE,
    headers: {
        common: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }
    },
    paramsSerializer: (params) => qs.stringify(params, { indices: false })
};