import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from '../utils/api/api';
import { apiConfig } from '../utils/constants';

class NewsletterService extends Api {
    public constructor(apiConfig: AxiosRequestConfig) {
        super(apiConfig);
        this.add = this.add.bind(this);
    }

    public add(email: string): Promise<boolean> {
        return this.post(`newsletter`, { email: email })
            .then((result: AxiosResponse<any>) => {
                return result.data
            });
    }
}

export const newsletterApi = new NewsletterService(apiConfig);