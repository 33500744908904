import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { CategoryViewModel } from '../types/CategoryViewModel';

import { Api } from '../utils/api/api';
import { apiConfig } from '../utils/constants';

class CategoryService extends Api {
    public constructor(apiConfig: AxiosRequestConfig) {
        super(apiConfig);

        this.getAll = this.getAll.bind(this);
    }

    public getAll(): Promise<Array<CategoryViewModel>> {
        return this.get<Array<CategoryViewModel>>(`category/get`)
            .then((result: AxiosResponse<Array<CategoryViewModel>>) => {
                return result.data
            });
    }
}

export const categoryApi = new CategoryService(apiConfig);