import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        "path": "/",
        "name": "Start",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Start" */ "@/views/Start.vue"),
        "meta": {
            "sitemap": {
                "priority": 0.7,
                "changefreq": "weekly",
                "lastmod": "2023-07-08",
                "index": true
            }
        }
    },
    {
        "path": "/knivar",
        "name": "Knifes",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Articles" */ "@/views/Articles.vue"),
        "props": { "categoryId": 2 },
        "meta": {
            "sitemap": {
                "priority": 0.7,
                "changefreq": "weekly",
                "lastmod": "2023-07-08",
                "index": true
            }
        }
    },
    {
        "path": "/fodral",
        "name": "Sheath",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Articles" */ "@/views/Articles.vue"),
        "props": { "categoryId": 3 },
        "meta": {
            "sitemap": {
                "priority": 0.7,
                "changefreq": "weekly",
                "lastmod": "2023-07-08",
                "index": true
            }
        }
    },
    {
        "path": "/skraddarsytt",
        "name": "CustomMade",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Articles" */ "@/views/Articles.vue"),
        "props": { "categoryId": 4 },
        "meta": {
            "sitemap": {
                "priority": 0.7,
                "changefreq": "weekly",
                "lastmod": "2023-07-08",
                "index": true
            }
        }
    },
    {
        "path": "/knivar/:slug",
        "name": "Article-knivar",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Article" */ "@/views/Article.vue"),
        "meta": {
            "sitemap": {
                "priority": 0.7,
                "changefreq": "weekly",
                "lastmod": "2023-07-08",
                "index": false
            }
        }
    },
    {
        "path": "/skraddarsytt/:slug",
        "name": "Article-skraddarsytt",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Article" */ "@/views/Article.vue"),
        "meta": {
            "sitemap": {
                "priority": 0.7,
                "changefreq": "weekly",
                "lastmod": "2023-07-08",
                "index": false
            }
        }
    },
    {
        "path": "/fodral/:slug",
        "name": "Article-fodral",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Article" */ "@/views/Article.vue"),
        "meta": {
            "sitemap": {
                "priority": 0.7,
                "changefreq": "weekly",
                "lastmod": "2023-07-08",
                "index": false
            }
        }
    },
    {
        "path": "/bestall/:slug",
        "name": "Order",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Order" */ "@/views/Order.vue"),
        "meta": {
            "sitemap": {
                "priority": 0.7,
                "changefreq": "weekly",
                "lastmod": "2023-07-08",
                "index": false
            }
        }
    },
    {
        "path": "/bestallt",
        "name": "OrderConfirm",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "OrderConfirm" */ "@/views/OrderConfirm.vue"),
        "meta": {
            "sitemap": {
                "priority": 0.7,
                "changefreq": "weekly",
                "lastmod": "2023-07-08",
                "index": false
            }
        }
    },
    {
        "path": "/kontakta-oss",
        "name": "ContactUs",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "ContactUs" */ "@/views/ContactUs.vue"),
        "meta": {
            "sitemap": {
                "priority": 0.7,
                "changefreq": "weekly",
                "lastmod": "2023-07-08",
                "index": true
            }
        }
    },
    {
        "path": "/admin/article/save/:id?",
        "name": "SaveArticle",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "SaveArticle" */ "@/views/admin/article/SaveArticle.vue"),
        "meta": {
            "sitemap": {
                "priority": 0.7,
                "changefreq": "weekly",
                "lastmod": "2023-07-08",
                "index": false
            }
        }
    },
    {
        "path": "/admin/articles",
        "name": "ListArticles",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "ListArticles" */ "@/views/admin/article/ListArticles.vue"),
        "meta": {
            "sitemap": {
                "priority": 0.7,
                "changefreq": "weekly",
                "lastmod": "2023-07-08",
                "index": false
            }
        }
    },
    {
        "path": "/admin/login",
        "name": "Login",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Login" */ "@/views/admin/Login.vue"),
        "meta": {
            "sitemap": {
                "priority": 0.7,
                "changefreq": "weekly",
                "lastmod": "2023-07-08",
                "index": false
            }
        }
    },
    {
        "path": '/:catchAll(.*)',
        "name": '404-default',
        component: () => import(/* webpackChunkName: "Error" */ '@/views/Error.vue'),
        "props": { "statusCode": 404 },
        "meta": {
            "sitemap": {
                "priority": 0.7,
                "changefreq": "weekly",
                "lastmod": "2023-07-08",
                "index": false
            }
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ top: 0 });
                }, 200); // V�nta 100 ms innan du scrollar upp
            });
        }
    },
});

router.beforeEach((to, from, next) => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
    });
    next();
});


export default router;