import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
//import { VueReCaptcha } from 'vue-recaptcha-v3';
import Popper from 'vue3-popper';
import router from './router/index';

createApp(App)
    .use(router)
    .use(createPinia())
    //.use(VueReCaptcha, {
    //    siteKey: '6LfjwuomAAAAALIKtZMFA_AqC-iqHTaYFlEgh7-f',
    //    autoHideBadge: true
    //})
    .component("Popper", Popper)
    .mount('#app');

declare global {
    interface Array<T> {
        groupBy(prop: keyof T): { [key: string]: T[] };
    }
}

declare global {
    interface Array<T> {
        groupBy<K extends keyof T>(prop: K): { [key: string]: T[] };
    }
}

Array.prototype.groupBy = function <T, K extends keyof T>(prop: K) {
    return this.reduce(function (groups: { [key: string]: T[] }, item: T) {
        const val = item[prop];
        groups[String(val)] = groups[String(val)] || [];
        groups[String(val)].push(item);
        return groups;
    }, {});
};